<template>
    <!-- <div v-show="!isOk" ><i icon="el-icon-loading"></i></div> -->
    <div v-show="isOk" style="height: 100vh;width:100vw;display: flex;
    flex-direction: column;justify-content: center;align-items: center;">
      <div style="margin-top:-100px;font-size: 20px;display: flex;
        flex-direction: column;justify-content: center;align-items: center;">
        <img src="@/assets/success2.png" style="width:60px" >
        <div style="margin-top: 10px;">已授权(0{{miaoshu}})</div>
      </div>
    </div>
</template>

<script>

import { Dialog,Notify} from "vant";
import { axios } from "axios";
import { agentAuthPc, agentCallbackPc, getUrlParam} from "@/api/user";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      code:'',
      userName:'',
      isOk:false,
      miaoshu:5,
      myTimeInt:undefined
    };
  },
  created () {      
    if(sessionStorage.getItem('pcLogin') == 1){
      // console.log('登录成功')
      this.toLogin()
    }else{
      // this.userName =getUrlParam("userName");
      this.getUrl()
    }
  },
  methods:{
    toLogin(){
      this.userName = getUrlParam("userName");
      this.code = getUrlParam("code");
      console.log('agentCallback参：'+this.userName,this.code)
      agentCallbackPc(this.code,this.userName).then((ress)=>{
        console.log(ress)
        sessionStorage.setItem('agentCallbackPc',JSON.stringify(ress))
        if(ress && ress.data.respCode == '200'){
          this.isOk = true
          sessionStorage.setItem('pcLogin', 0)
          this.miaoshu = this.miaoshu - 1
          this.myTimeInt = setInterval(()=>{
            this.miaoshu = this.miaoshu - 1
            if(this.miaoshu == 0){wx.closeWindow();clearInterval(this.miaoshu)}
          },1000)
          
          // Dialog.confirm({
          // title: "提示",
          // message: "授权成功",
          // type: "success"
          // }).then(() => {
          //   window.close()
          // }).catch(() => {
          //   window.close()
          // });
        }else{
          
        }
      })
    },
    getUrl(){
        // alert(window.location.href)
        let url = window.location.href
        agentAuthPc(url).then((res)=>{
          console.log(res)
          sessionStorage.setItem('pcLogin',1)
          sessionStorage.setItem('agentAuthPc',JSON.stringify(res))
          window.location.href = res.data
        })


        // window.location.href = 'http://card.wxd-iot.com/order/wx/v1/user/agentAuth'+a

        // axios ({
        //     method: "get",
        //     url: 'http://card.wxd-iot.com/order/wx/v1/user/agentAuth'+a,
        // }).then (res => {
        //     ///打印得到的数据
        //     console.log(res);
        // })
        // axios.get('http://card.wxd-iot.com/order/wx/v1/user/agentAuth'+a).then((res)=>{console.log(res)})
        // axios.get('http://card.wxd-iot.com/order/wx/v1/user/agentAuth',{
        //   params: {
        //     userName:'chuixa'
        //   }
        // }).then((res) => {
        //   console.log(res)
        // })
        // axios.post('http://card.wxd-iot.com/order/wx/v1/user/agentAuth'+a).then((res)=>{console.log(res)})
        // axios.post('http://card.wxd-iot.com/order/wx/v1/user/agentAuth',{
        //   params: {
        //     userName:'chuixa'
        //   }
        // }).then((res) => {
        //   console.log(res)
        // })
        // setTimeout(()=>{
        //   window.close()
        // },1000) 
        // Notify({ type: 'error', message: '授权失败'});
      
    }
  }
  }
</script>
<style></style>